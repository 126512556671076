<template>
  <b-card class="scroll">
    <app-timeline>

      <div
        v-for="commentary in commentaries"
        :key="commentary.id"
      >
        <app-timeline-item
          :icon="iconCommentary(commentary)"
          :variant="variantCommentary(commentary, false)"
          class="mb-2"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-2">
            <small class="text-muted text-right">{{ commentary.createdAt | luxon({ output: "dd/MM/y HH:mm" }) }}</small>
            <b-badge
              v-if="commentary.internal"
              pill
              variant="light-warning"
            >
              {{ $t('INTERNAL_COMMENTARY') }}
            </b-badge>
          </div>
          <div v-if="commentaryToTranslate.includes(commentary.commentary)">
            <p>
              {{ $t(commentary.commentary) }}
            </p>
            <b-form-rating
              v-if="commentary.commentary === 'RECLAMATION_CLOSED_COMMENT'"
              v-model="reclamation.satisfaction"
              variant="warning"
              size="lg"
              inline
              no-border
              class="mb-1"
              readonly
            />
          </div>
          <p v-else>
            {{ commentary.commentary }}
          </p>
          <b-media class="mb-2">
            <template #aside>
              <b-avatar :variant="variantCommentary(commentary, true)">
                <feather-icon icon="UserIcon" />
              </b-avatar>
            </template>
            <h6>{{ commentary.user.firstName }} {{ commentary.user.lastName }}</h6>
          </b-media>
          <hr>
        </app-timeline-item>
      </div>

    </app-timeline>

  </b-card>
</template>

<script>
import {
  BAvatar, BCard, BBadge, BMedia, BFormRating,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BAvatar,
    BCard,
    BBadge,
    BFormRating,
    BMedia,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    idReclamation: {
      type: Number,
      default: () => null,
    },
    reclamation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      commentaryToTranslate: ['RECLAMATION_TAKE_IN_CHARGE_COMMENT', 'RECLAMATION_RESOLVED_COMMENT', 'RECLAMATION_CLOSED_COMMENT', 'RECLAMATION_REOPEN_COMMENT'],
    }
  },
  computed: {
    commentaries() {
      return this.$store.getters['reclamations/getReclamationCommentaries']
    },
  },
  watch: {
    idReclamation(newId) {
      this.$store.dispatch('reclamations/getCommentaries', newId)
    },
  },
  mounted() {
    this.$store.dispatch('reclamations/getCommentaries', this.idReclamation)
  },
  methods: {
    iconCommentary() {
      return 'UserIcon'
    },
    variantCommentary(commentary, user) {
      let variant = ''
      if (user) variant = 'light-'
      if (commentary.user.company !== null) variant += 'success'
      else variant += 'info'
      return variant
    },
  },
}
</script>

<style scoped>
.scroll{
  max-height: 300px;
  overflow-y: auto;
}

.scroll::-webkit-scrollbar {
  width: 15px;
}

.scroll::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 100px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #8070d4;
  background-color: #999;
}
</style>
