<template>
  <b-row class="mt-2">
    <b-col
      xs="1"
      md="1"
      lg="1"
    />
    <b-col
      v-for="item in status"
      :key="item.icon"
      xs="2"
      md="2"
      lg="2"
      class="centerdCard"
    >
      <b-avatar
        class="mb-1"
        :variant="statusOk(item.label)"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="item.icon"
        />
      </b-avatar>
      <div class="truncate">
        <h4 class="mb-25 font-weight-bolder">
          {{ $t(item.label) }}
        </h4>
      </div>

    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    status: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: () => 'NEW',
    },
  },
  methods: {
    statusOk(value) {
      // eslint-disable-next-line default-case
      switch (this.active) {
        case 'NEW':
          if (['NEW'].includes(value)) return 'light-success'
          break
        case 'ASSIGNED':
          if (['NEW', 'ASSIGNED'].includes(value)) return 'light-success'
          break
        case 'PROCESSING':
          if (['NEW', 'ASSIGNED', 'PROCESSING'].includes(value)) return 'light-success'
          break
        case 'RESOLVED':
          if (['NEW', 'ASSIGNED', 'PROCESSING', 'RESOLVED'].includes(value)) return 'light-success'
          break
        case 'CLOSED':
          if (['NEW', 'ASSIGNED', 'PROCESSING', 'RESOLVED', 'CLOSED'].includes(value)) return 'light-success'
          break
      }
      return 'light-danger'
    },
  },

}
</script>

<style scoped>
.centerdCard{
  text-align: center;
}
</style>
