<template>
  <b-card v-if="reclamation.status !== 'RESOLVED'">
    <b-card-title>
      {{ $t('RECLAMATION_NEW_COMMENTARY') }}
    </b-card-title>
    <b-card-body>
      <b-row v-if="isBetrancourt">
        <b-col class="mb-2">
          <b-form-checkbox
            v-model="internal"
            checked="false"
            class="custom-control-warning"
            switch
            inline
          >
            {{ $t('INTERNAL_COMMENTARY') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-textarea
            v-model="commentary"
            rows="8"
          />
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer>
      <!-- submit buttons -->
      <b-button
        v-if="loading"
        variant="primary"
        type="button"
        block
        disabled
      >
        <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        variant="primary"
        block
        @click="insertCommentary"
      >
        {{ $t('SEND_COMMENTARY') }}
      </b-button>
    </b-card-footer>
  </b-card>

  <div v-else>
    <b-card v-if="!isBetrancourt">
      <b-card-title>
        {{ $t('RECLAMATION_RATING') }}
      </b-card-title>
      <b-card-body>
        <b-row>
          <b-card-text>
            <span>{{ $t('RECLAMATION_RATING_TEXT') }}</span>
          </b-card-text>
          <div>
            <b-form-rating
              v-model="rating"
              variant="warning"
              size="lg"
              inline
              no-border
              class="mt-1"
            />
          </div>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <!-- submit buttons -->
        <b-button
          v-if="loading"
          variant="primary"
          type="button"
          block
          disabled
        >
          <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="primary"
          block
          @click="sendRating"
        >
          {{ $t('SEND_RATING') }}
        </b-button>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardText, BCardFooter, BFormTextarea, BButton, BFormCheckbox, BRow, BCol, BSpinner, BFormRating,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BCardText,
    BCardFooter,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    BSpinner,
    BFormRating,
  },
  directives: {
    Ripple,
  },
  props: {
    reclamation: {
      type: Object,
      default: () => {},
    },
    isBetrancourt: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loading: false,
      internal: false,
      commentary: '',
      rating: 3,
    }
  },
  methods: {
    insertCommentary() {
      this.loading = true
      this.$store.dispatch('reclamations/postCommentary', { reclamation: this.reclamation['@id'], commentary: this.commentary, internal: this.internal })
        .then(() => {
          this.$store.dispatch('reclamations/getCommentaries', this.reclamation.id)
          this.$swal({
            title: `${this.$i18n.t('COMMENTARY_CREATED_POPUP_HEADER')}`,
            html: `${this.$i18n.t('RECLAMATION_POPUP_TEXT_2')}.<br /><br /><small>${this.$i18n.t('RECLAMATION_POPUP_TEXT_3')}.</small>`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loading = false
          this.commentary = ''
        })
        .catch(e => {
          if (e.response && e.response.data) {
            this.errorInsert = this.$i18n.t(e.response.data)
          } else {
            this.errorInsert = this.$i18n.t(e)
          }
          this.errorShow = true
          this.loading = false
        })
    },
    sendRating() {
      this.$emit('closeReclamation', { idReclamation: this.reclamation.id, function: 'closeReclamation', rating: this.rating })
    },
  },
}
</script>
