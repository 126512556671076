<template>
  <b-overlay
    :show="showOverlay"
    rounded="xl"
  >
    <b-card>
      <b-card-body>
        <b-row>
          <b-col
            md="6"
            sm="12"
          >
            <h2>
              <b-badge :variant="variantStatut(reclamation.status)">
                {{ $t(reclamation.status) }}
              </b-badge>
              {{ $t('RECLAMATION_NUMBER') }} {{ idReclamation }}
            </h2>
          </b-col>
          <b-col
            v-if="canAccess() && !loading"
            md="6"
            sm="12"
            class="text-right"
          >

            <b-button
              v-if="isBetrancourt && reclamation.status === 'NEW'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              @click="takeInCharge"
            >
              {{ $t('RECLAMATION_TAKE_CHARGE') }}
            </b-button>

            <b-button
              v-if="isBetrancourt && reclamation.status === 'PROCESSING'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              @click="resolveReclamation"
            >
              {{ $t('RECLAMATION_RESOLVE_BUTTON') }}
            </b-button>

            <b-button
              v-if="!isBetrancourt && reclamation.status === 'RESOLVED'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="ml-1"
              variant="danger"
              @click="scrollToClass('newCommentaryCard')"
            >
              {{ $t('RECLAMATION_CLOSE_BUTTON') }}
            </b-button>

            <b-button
              v-if="canReopen()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="ml-1"
              @click="reopenReclamation"
            >
              {{ $t('RE_OPEN_RECLAMATION_BUTTON') }}
            </b-button>

            <b-button
              v-if="loading"
              variant="primary"
              type="button"
              disabled
            >
              <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon ml-1"
              @click="$router.go(-1)"
            >
              <feather-icon
                icon="RewindIcon"
              />
              {{ $t('BUTTON_BACK') }}
            </b-button>
          </b-col>
        </b-row>
        <timeline-status
          :status="status"
          :active="reclamation.status"
        />
      </b-card-body>
    </b-card>

    <b-card-actions
      no-body
      action-collapse
      :collapsed="cardTimeCollapsed"
      :title="$t('RECLAMATION_CARD_DATE_TITLE')"
      class="card-statistics"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            md="4"
            sm="4"
            class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="UserPlusIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ $t("CREATED_AT") }} : {{ reclamation.createdAt | luxon({ output: "dd/MM/y HH:mm" }) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  <span v-if="reclamation.createdBy">{{ $t("CREATED_BY") }} : {{ reclamation.createdBy.firstName }} {{ reclamation.createdBy.lastName }} </span>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col
            md="4"
            sm="4"
            class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-warning"
                >
                  <feather-icon
                    size="24"
                    icon="AlertCircleIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ $t("LAST_UPDATE") }} : {{ reclamation.updatedAt | luxon({ output: "dd/MM/y HH:mm" }) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  <span v-if="reclamation.updatedUser">{{ $t("LAST_UPDATE_USER") }} : {{ reclamation.updatedUser.firstName }} {{ reclamation.updatedUser.lastName }}</span>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col
            v-if="reclamation.closedAt"
            md="4"
            sm="4"
            class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="ThumbsUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ $t("CLOSED_AT") }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  <span> {{ reclamation.closedAt | luxon({ output: "dd/MM/y HH:mm" }) }}</span>
                </b-card-text>

              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card-actions>

    <b-card-actions
      v-if="isBetrancourt"
      no-body
      action-collapse
      :collapsed="cardDelayCollapsed"
      :title="$t('RECLAMATION_CARD_BETRANCOURT_DELAY_TITLE')"
      class="card-statistics"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-if="reclamation.supportedAt"
            md="6"
            sm="6"
            class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="ClockIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4
                  class="font-weight-bolder mb-0"
                >
                  {{ delay(reclamation.createdAt, reclamation.supportedAt, true) }} {{ $t('DAYS') }} {{ delay(reclamation.createdAt, reclamation.supportedAt) }} {{ $t('HOURS') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  <span>{{ $t("RECLAMATION_TAKEINCHARGE_DELAY") }}</span>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col
            v-if="reclamation.closedAt"
            md="4"
            sm="4"
            class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-danger"
                >
                  <feather-icon
                    size="24"
                    icon="ClockIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4
                  class="font-weight-bolder mb-0"
                >
                  {{ delay(reclamation.createdAt, reclamation.closedAt, true) }} {{ $t('DAYS') }} {{ delay(reclamation.createdAt, reclamation.closedAt) }} {{ $t('HOURS') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  <span>{{ $t("RECLAMATION_CLOSED_DELAY") }}</span>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card-actions>

    <form-detail
      v-if="reclamation.id"
      :reclamation="reclamation"
      :is-betrancourt="isBetrancourt"
    />

    <b-row>
      <b-col
        sm="12"
        md="6"
        lg="6"
      >
        <timeline-commentaries
          v-if="reclamation.id"
          :id-reclamation="reclamation.id"
          :reclamation="reclamation"
        />
      </b-col>

      <b-col
        sm="12"
        md="6"
        lg="6"
      >
        <new-commentary
          v-if="reclamation.id && reclamation.status !== 'CLOSED' && canAccess()"
          class="newCommentaryCard"
          :reclamation="reclamation"
          :is-betrancourt="isBetrancourt"
          @closeReclamation="closeReclamation"
        />
      </b-col>
    </b-row>

  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardBody, BCardText, BRow, BCol, BBadge, BMedia, BMediaAside, BAvatar, BMediaBody, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import timelineStatus from './details/timelineStatus.vue'
import formDetail from './details/formDetail.vue'
import timelineCommentaries from './details/timelineCommentaries.vue'
import newCommentary from './details/newCommentary.vue'

export default {
  components: {
    BButton,
    BOverlay,
    BCard,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    timelineStatus,
    formDetail,
    timelineCommentaries,
    newCommentary,
    BCardActions,
    BSpinner,
    // pjDetailReclamation,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      cardTimeCollapsed: true,
      cardDelayCollapsed: true,
      idReclamation: '',
      showOverlay: true,
      isBetrancourt: false,
      status: [
        { value: 'NEW', label: 'NEW', icon: 'LayersIcon' },
        { value: 'ASSIGNED', label: 'ASSIGNED', icon: 'LayersIconPlus' },
        { value: 'PROCESSING', label: 'PROCESSING', icon: 'FilePlusIcon' },
        { value: 'RESOLVED', label: 'RESOLVED', icon: 'InboxIcon' },
        { value: 'CLOSED', label: 'CLOSED', icon: 'ThumbsUpIcon' },
      ],
    }
  },
  computed: {
    userInfos() {
      return this.$store.getters['users/getUserProfile']
    },
    reclamation() {
      return this.$store.getters['reclamations/reclamation']
    },
  },
  mounted() {
    this.$store.dispatch('users/getUserInfos', JSON.parse(localStorage.getItem('userData')).id)
      .then(r => {
        if (r.roles.indexOf('ROLE_ADMIN') !== -1 || r.roles.indexOf('ROLE_TECHNICIAN') !== -1) {
          this.isBetrancourt = true
        } else {
          this.isBetrancourt = false
        }
      })

    if (this.$route.params.id && Number.isInteger(parseInt(this.$route.params.id, 10))) {
      this.idReclamation = this.$route.params.id
      this.$store.dispatch('reclamations/fetchOne', this.$route.params.id)
        .then(() => {
          this.showOverlay = false
        }).catch(() => {
          this.$router.push('/pages/error-404')
        })
    } else {
      this.$router.push('/pages/error-404')
    }
  },
  methods: {
    canAccess() {
      let canAccess = false
      const idCurrentUser = JSON.parse(localStorage.userData).id
      if ((this.reclamation.userClient && this.reclamation.userClient.id === idCurrentUser) || (this.reclamation.userTechnique && this.reclamation.userTechnique.id === idCurrentUser)) {
        canAccess = true
      }

      if (JSON.parse(localStorage.userData).role === 'superviseur') {
        canAccess = true
      }
      if (JSON.parse(localStorage.userData).company === null) {
        canAccess = true
      }
      return canAccess
    },
    canReopen() {
      if (JSON.parse(localStorage.userData).company === null) {
        if (this.reclamation.status === 'RESOLVED' && this.canAccess()) {
          return true
        }

        // Un user Betrancourt peut reouvrir une reclamation cloturée
        if (this.canAccess() && JSON.parse(localStorage.userData).company === null && this.reclamation.status === 'CLOSED') {
          return true
        }

        return false
      }
      return false
    },
    variantStatut(statut) {
      let returnStatut = 'secondary'
      // eslint-disable-next-line default-case
      switch (statut) {
        case 'NEW':
          returnStatut = 'danger'
          break
        case 'ASSIGNED':
        case 'PROCESSING':
          returnStatut = 'warning'
          break
        case 'RESOLVED':
          returnStatut = 'success'
          break
        case 'CLOSED':
          returnStatut = 'danger'
          break
      }
      return returnStatut
    },
    takeInCharge() {
      this.specificUpdateReclamation('takeInCharge')
    },
    resolveReclamation() {
      this.specificUpdateReclamation('resolve')
    },
    closeReclamation(params) {
      this.specificUpdateReclamation(params.function, params.rating)
    },
    reopenReclamation() {
      this.specificUpdateReclamation('reopen')
    },
    scrollToClass(refName) {
      this.$el.getElementsByClassName(refName)[0].scrollIntoView({ behavior: 'smooth' })
    },
    specificUpdateReclamation(data, rating) {
      this.loading = true
      const params = { idReclamation: this.reclamation.id, data }
      if (rating) params.rating = rating
      this.$store.dispatch('reclamations/update', params)
        .then(() => {
          this.$store.dispatch('reclamations/getCommentaries', this.idReclamation)
          let headerPopup = 'SUCCESS_RECLAMATION_UPDATE'
          switch (data) {
            case 'takeInCharge':
              headerPopup = 'SUCCESS_RECLAMATION_TAKE_IN_CHARGE'
              break
            case 'resolve':
              headerPopup = 'SUCCESS_RECLAMATION_RESOLVE'
              break
            case 'reopen':
              headerPopup = 'SUCCESS_RECLAMATION_REOPEN'
              break
            default:
              break
          }
          this.$store.dispatch('reclamations/stats')
          this.$swal(
            {
              title: `${this.$i18n.t(headerPopup)}`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            },
          )
          this.loading = false
        })
        .catch(() => {
          this.$swal(
            {
              title: `${this.$i18n.t('ERROR_RECLAMATION_UPDATE')}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            },
          )
          this.loading = false
        })
    },
    delay(date1, date2, displayDays = false) {
      const d1 = new Date(date1)
      const d2 = new Date(date2)
      const delay = d2.getTime() - d1.getTime()

      const delayInDays = delay / 1000 / 3600 / 24
      const days = Math.trunc(delayInDays)
      const hours = Math.trunc((Number(delayInDays - days).toFixed(2) * 24))
      if (displayDays) return days
      return hours
    },
  },
}
</script>
