<template>

  <b-card>
    <b-card-body>
      <!-- form -->
      <b-form class="mt-2">
        <validation-observer ref="updateReclamationForm">
          <b-row>
            <b-col sm="6">
              <b-form-group
                :label="$t('RECLAMATION_EXTERNAL_DOC_NUMBER')"
              >
                <b-form-input
                  v-model="reclamationForm.externalDocNumber"
                  :disabled="!can_update('externalDocNumber')"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                v-if="isBetrancourt"
                :label="$t('USER_TECHNIQUE')"
              >
                <v-select
                  v-model="reclamationForm.userTechnique"
                  :options="usersBetrancourt"
                  :clearable="false"
                  :disabled="!can_update('userTechnique')"
                >
                  <template #option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                  <template #selected-option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="isBetrancourt"
              md="6"
            >
              <b-form-group
                :label="$t('COMPANY')"
              >
                <v-select
                  v-model="reclamationForm.company"
                  :options="companies"
                  :clearable="false"
                  :disabled="!can_update('company')"
                  @input="updateUserCompany"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <template>
                  {{ $t('RECLAMATION_USER_CLIENT') }}
                  <b-button
                    v-if="reclamation.userClient"
                    v-b-popover.hover.top="infoUser(reclamation.userClient, 'body')"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :title="infoUser(reclamation.userClient, 'title')"
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    size="sm"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                </template>
                <v-select
                  v-model="reclamationForm.userClient"
                  :options="getNotifiedUser('userClient')"
                  name="userClient"
                  :disabled="!can_update('userClient')"
                >
                  <template #option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                  <template #selected-option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <b-form-group
                :label="$t('RECLAMATION_TYPE')"
              >
                <v-select
                  v-model="reclamationForm.reclamationType"
                  :options="reclamationType"
                  :clearable="false"
                  :disabled="!can_update('reclamationType')"
                  @input="updateType"
                >
                  <template #option="{ name }">
                    {{ $t(name) }}
                  </template>
                  <template #selected-option="{name}">
                    {{ $t(name) }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="$t('RECLAMATION_SUBTYPE')"
              >
                <v-select
                  v-model="reclamationForm.reclamationSubType"
                  :options="reclamationSubTypeByType"
                  :disabled="!can_update('reclamationSubType')"
                  :clearable="false"
                >
                  <template #option="{ name }">
                    {{ $t(name) }}
                  </template>
                  <template #selected-option="{name}">
                    {{ $t(name) }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col
              v-if="isBetrancourt"
              sm="6"
            >
              <b-form-group
                :label="$t('RECLAMATION_SUBSUBTYPE')"
              >
                <v-select
                  v-model="reclamationForm.reclamationSubSubType"
                  :options="reclamationSubSubType"
                >
                  <template #option="{ name }">
                    {{ $t(name) }}
                  </template>
                  <template #selected-option="{name}">
                    {{ $t(name) }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                :label="$t('RECLAMATION_TITLE')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('RECLAMATION_TITLE')"
                  rules="required|min:10"
                >
                  <b-form-input
                    v-model="reclamationForm.title"
                    :disabled="!can_update('title')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('RECLAMATION_DETAIL')"
              >
                <b-form-textarea
                  v-model="reclamationForm.detail"
                  :disabled="!can_update('detail')"
                  type="text"
                  rows="10"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <hr class="reclamation-spacing">

          <b-row>
            <b-col md="12">
              <h4>{{ $t('RECLAMATION_PJ') }}</h4>
            </b-col>

            <div
              v-if="reclamation.files && reclamation.files.length > 0"
              id="icons-container"
              class="d-flex flex-wrap"
            >
              <b-card
                v-for="file in reclamation.files"
                :key="file.id"
                v-b-tooltip.hover.top="file.name"
                class="icon-card cursor-pointer text-center mb-2"
                @click="downloadPJ(file)"
              >
                <b-avatar
                  :variant="VariantByExtension(file.extension)"
                  rounded
                  size="50"
                >
                  <feather-icon
                    size="24"
                    icon="FileIcon"
                  />
                </b-avatar>
                <b-card-text>
                  {{ file.extension }}
                </b-card-text>
              </b-card>
            </div>
            <b-col md="12">
              <vue-dropzone
                v-if="reclamation.status !== 'RESOLVED' && reclamation.status !== 'CLOSED'"
                id="fileUpload"
                ref="dropzone"
                :options="dropOptions"
                :use-custom-slot="true"
                @vdropzone-file-added="afterComplete"
                @vdropzone-removed-file="afterRemove"
              >
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    {{ $t('DRAG_DROP_PLACEHOLDER') }}
                  </h3><br>
                  <span>{{ $t('DRAG_DROP_AUTHORIZED_FILES') }}</span>
                </div>

              </vue-dropzone>
            </b-col>

          </b-row>
          <hr class="reclamation-spacing">

          <b-row>
            <b-col md="12">
              <h4>{{ $t('RECLAMATION_NOTIFY_USERS') }}</h4>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('RECLAMATION_CONTACT_1')"
                label-for="contact1"
              >
                <v-select
                  v-model="reclamationForm.contact1"
                  :options="getNotifiedUser('contact1')"
                  name="contact1"
                  :disabled="!can_update('contact1')"
                >
                  <template #option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                  <template #selected-option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('RECLAMATION_CONTACT_2')"
                label-for="contact2"
              >
                <v-select
                  v-model="reclamationForm.contact2"
                  :options="getNotifiedUser('contact2')"
                  name="contact2"
                  :disabled="!can_update('contact2')"
                >
                  <template #option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                  <template #selected-option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="isBetrancourt">
            <b-col md="12">
              <h4>{{ $t('RECLAMATION_NOTIFY_TECHNICIAN') }}</h4>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('RECLAMATION_NOTIFIED_USER_1')"
                label-for="notifiedUser1"
              >
                <v-select
                  v-model="reclamationForm.notifiedUser1"
                  :options="getNotifiedUser2('notifiedUser1')"
                  name="notifiedUser1"
                  :disabled="!can_update('notifiedUser1')"
                >
                  <template #option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                  <template #selected-option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('RECLAMATION_NOTIFIED_USER_2')"
                label-for="notifiedUser2"
              >
                <v-select
                  v-model="reclamationForm.notifiedUser2"
                  :options="getNotifiedUser2('notifiedUser2')"
                  name="notifiedUser2"
                  :disabled="!can_update('notifiedUser2')"
                >
                  <template #option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                  <template #selected-option="{firstName, lastName}">
                    {{ firstName }} {{ lastName }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

        </validation-observer>
      </b-form>

      <b-row v-if="canSend()">
        <b-col offset-md="4">
          <!-- submit buttons -->
          <b-button
            v-if="loading"
            variant="primary"
            type="button"
            disabled
          >
            <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            class="mr-1"
            variant="primary"
            @click="submitReclamation"
          >
            {{ $t('UPDATE_RECLAMATION_BUTTON') }}
          </b-button>
        </b-col>
      </b-row>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAvatar, BCard, BCardBody, BCardText, BForm, BFormGroup, BFormInput, BFormTextarea, BRow, BCol, VBTooltip, BButton, BSpinner, VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vueDropzone from 'vue2-dropzone'

export default {
  components: {
    BAvatar,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    vueDropzone,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    reclamation: {
      type: Object,
      default: () => { },
    },
    isBetrancourt: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      required,
      loading: false,
      reclamationForm: {
        title: '',
        detail: '',
        company: '',
        externalDocNumber: '',
        contact1: '',
        contact2: '',
        userClient: '',
        userTechnique: '',
        reclamationType: '',
        reclamationSubType: '',
        reclamationSubSubType: '',
        notifiedUser1: '',
        notifiedUser2: '',
      },
      reclamationFiles: [],
      files: [],
      dropOptions: {
        autoProcessQueue: false,
        url: 'http://localhost/',
        maxFilesize: 2,
        maxFiles: 5,
        chunking: false,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles: 'image/*,.xlsx,.xls,.pdf,.doc,.docx, .zip',
      },
    }
  },
  computed: {
    reclamationType() {
      return this.$store.getters['reclamationType/getTypes']
    },
    reclamationSubType() {
      return this.$store.getters['reclamationSubType/getSubTypes']
    },
    reclamationSubSubType() {
      return this.$store.getters['reclamationSubSubType/getSubSubTypes']
    },
    reclamationSubTypeByType() {
      return this.$store.getters['reclamationSubType/getSubTypesByType']
    },
    usersCompany() {
      return this.$store.getters['companies/getUsersByCompany']
    },
    usersBetrancourt() {
      return this.$store.getters['users/getUsersBetrancourt']
    },
    companies() {
      return this.$store.getters['companies/getCompanies']
    },
  },
  mounted() {
    this.$store.dispatch('reclamationType/fetchAll').then(() => {
      this.$store.dispatch('reclamationSubType/fetchAll').then(() => {
        if (JSON.parse(localStorage.getItem('userData')).company !== null) {
          this.$store.dispatch('companies/fetchAll').then(() => {
            this.updateReclamation()
            this.updateUserCompany()
          })
        } else {
          this.$store.dispatch('companies/fetchAll').then(() => {
            this.$store.dispatch('reclamationSubSubType/fetchAll')
            this.updateReclamation()
            this.$store.dispatch('users/fetchAllUsersBetrancourt')
            this.updateUserCompany()
          })
        }
      })
    })
  },
  methods: {
    getNotifiedUser2(user) {
      const userExclure = []
      const users = this.usersBetrancourt
      if (user === 'userClient') {
        if (this.reclamationForm.notifiedUser1 !== '' && this.reclamationForm.notifiedUser1 !== null) userExclure.push(this.reclamationForm.notifiedUser1['@id'])
        if (this.reclamationForm.notifiedUser2 !== '' && this.reclamationForm.notifiedUser2 !== null) userExclure.push(this.reclamationForm.notifiedUser2['@id'])
      }
      if (user === 'notifiedUser1') {
        if (this.reclamationForm.userClient !== '' && this.reclamationForm.userClient !== null) userExclure.push(this.reclamationForm.userClient['@id'])
        if (this.reclamationForm.notifiedUser2 !== '' && this.reclamationForm.notifiedUser2 !== null) userExclure.push(this.reclamationForm.notifiedUser2['@id'])
      }
      if (user === 'notifiedUser2') {
        if (this.reclamationForm.notifiedUser1 !== '' && this.reclamationForm.notifiedUser1 !== null) userExclure.push(this.reclamationForm.notifiedUser1['@id'])
        if (this.reclamationForm.userClient !== '' && this.reclamationForm.userClient !== null) userExclure.push(this.reclamationForm.userClient['@id'])
      }
      return users.filter(el => !userExclure.includes(el['@id']))
    },
    getNotifiedUser(contact) {
      const userExclure = []
      const users = this.usersCompany
      if (contact === 'userClient') {
        if (this.reclamationForm.contact1 !== '' && this.reclamationForm.contact1 !== null) userExclure.push(this.reclamationForm.contact1['@id'])
        if (this.reclamationForm.contact2 !== '' && this.reclamationForm.contact2 !== null) userExclure.push(this.reclamationForm.contact2['@id'])
      }
      if (contact === 'contact1') {
        if (this.reclamationForm.userClient !== '' && this.reclamationForm.userClient !== null) userExclure.push(this.reclamationForm.userClient['@id'])
        if (this.reclamationForm.contact2 !== '' && this.reclamationForm.contact2 !== null) userExclure.push(this.reclamationForm.contact2['@id'])
      }
      if (contact === 'contact2') {
        if (this.reclamationForm.contact1 !== '' && this.reclamationForm.contact1 !== null) userExclure.push(this.reclamationForm.contact1['@id'])
        if (this.reclamationForm.userClient !== '' && this.reclamationForm.userClient !== null) userExclure.push(this.reclamationForm.userClient['@id'])
      }
      return users.filter(el => !userExclure.includes(el['@id']))
    },
    updateReclamation() {
      this.reclamationForm = {
        title: this.reclamation.title,
        detail: this.reclamation.detail,
        externalDocNumber: this.reclamation.externalDocNumber,
        userClient: this.reclamation.userClient,
        reclamationSubSubType: '',
        reclamationType: '',
        reclamationSubType: '',
        company: '',
        contact1: this.reclamation.contact1 ? this.reclamation.contact1 : '',
        contact2: this.reclamation.contact2 ? this.reclamation.contact2 : '',
        notifiedUser1: this.reclamation.notifiedUser1 ? this.reclamation.notifiedUser1 : '',
        notifiedUser2: this.reclamation.notifiedUser2 ? this.reclamation.notifiedUser2 : '',
        userTechnique: this.reclamation.userTechnique ? this.reclamation.userTechnique : '',
      }

      if (this.reclamation.company) {
        this.reclamationForm.company = {
          id: this.reclamation.company.id,
          name: this.reclamation.company.name,
          label: this.reclamation.company.name,
          value: this.reclamation.company['@id'],
          uri: this.reclamation.company['@id'],
        }
      }

      if (this.reclamation.reclamationType) {
        this.reclamationForm.reclamationType = {
          label: this.reclamation.reclamationType.name,
          name: this.reclamation.reclamationType.name,
          uri: this.reclamation.reclamationType['@id'],
          value: this.reclamation.reclamationType.id,
          '@id': this.reclamation.reclamationType['@id'],
        }
        this.updateType(true)
      }

      if (this.reclamation.reclamationSubType) {
        this.reclamationForm.reclamationSubType = {
          label: this.reclamation.reclamationSubType.name,
          name: this.reclamation.reclamationSubType.name,
          uri: this.reclamation.reclamationSubType['@id'],
          value: this.reclamation.reclamationSubType.id,
          '@id': this.reclamation.reclamationSubType['@id'],
        }
      }

      if (this.reclamation.ReclamationSubSubType) {
        this.reclamationForm.reclamationSubSubType = {
          label: this.reclamation.ReclamationSubSubType.name,
          name: this.reclamation.ReclamationSubSubType.name,
          uri: this.reclamation.ReclamationSubSubType['@id'],
          value: this.reclamation.ReclamationSubSubType.id,
          '@id': this.reclamation.ReclamationSubSubType['@id'],
        }
      }
    },
    updateType(load = false) {
      if (this.reclamationForm.reclamationType && this.reclamationForm.reclamationType['@id']) {
        this.$store.dispatch('reclamationSubType/fetchAllByType', this.reclamationForm.reclamationType['@id']).then(() => {
          if (!load) {
            // eslint-disable-next-line prefer-destructuring
            this.reclamationForm.reclamationSubType = this.reclamationSubTypeByType[0]
          }
        })
      }
    },
    updateUserCompany() {
      if (this.reclamationForm.company) {
        this.$store.dispatch('companies/fetchAllUsersByCompany', this.reclamationForm.company)
      }
    },
    infoUser(user, type) {
      if (type === 'title') return `${user.firstName} ${user.lastName}`
      let text = ''
      if (user.email) text += `Email : ${user.email} `
      if (user.phone) text += `Tel : ${user.phone} `
      return `${text}`
    },
    VariantByExtension(extension) {
      switch (extension) {
        case 'png':
          return 'primary'

        case 'pdf':
          return 'danger'

        case 'xls':
        case 'xlsx':
          return 'success'

        default:
          return 'primary'
      }
    },
    afterComplete(file) {
      this.reclamationFiles.push(file)
      return true
    },
    afterRemove(file) {
      const files = this.reclamationFiles.filter(fichier => fichier.upload !== file.upload)
      this.reclamationFiles = files
      return true
    },
    downloadPJ(file) {
      this.$store.dispatch('reclamations/getPj', file)
    },
    can_update(field) {
      if (this.reclamation.status !== 'CLOSED' && this.reclamation.status !== 'RESOLVED') {
        if (this.isBetrancourt) {
          if (['title', 'reclamationType', 'reclamationSubType', 'contact1', 'contact2', 'notifiedUser1', 'notifiedUser2', 'userTechnique'].includes(field)) {
            return true
          }
        } else if (JSON.parse(localStorage.userData).role === 'superviseur') {
          if (['contact1', 'contact2'].includes(field)) {
            return true
          }
        } else if (JSON.parse(localStorage.userData).id === this.reclamation.userClient.id) {
          if (['contact1', 'contact2'].includes(field)) {
            return true
          }
        }
      }
      return false
    },
    canSend() {
      if (this.reclamation.status !== 'CLOSED' && this.reclamation.status !== 'RESOLVED') {
        if (this.isBetrancourt) {
          return true
        }
        if (JSON.parse(localStorage.userData).role === 'superviseur') {
          return true
        }
        if (this.reclamationForm && this.reclamationForm.userClient && this.reclamationForm.userClient.id === JSON.parse(localStorage.getItem('userData')).id) {
          return true
        }
      }
      return false
    },
    submitReclamation() {
      this.loading = true
      const form = new FormData()
      if (this.reclamationFiles.length) {
        for (let i = 0; i < this.reclamationFiles.length; i += 1) {
          form.append(`files[${i}]`, this.reclamationFiles[i])
        }
      }
      form.append('id', this.reclamation.id)

      if (this.reclamationForm.title && this.reclamationForm.title !== this.reclamation.title) form.append('title', this.reclamationForm.title)
      if (this.reclamationForm.detail && this.reclamationForm.detail !== this.reclamation.detail) form.append('detail', this.reclamationForm.detail)
      if (this.reclamationForm.type && this.reclamationForm.type !== this.reclamation.type) form.append('reclamationType', this.reclamationForm.type.id)
      if (this.reclamationForm.subType && this.reclamationForm.subType !== this.reclamation.subType) form.append('reclamationSubType', this.reclamationForm.subType.id)
      if (this.reclamationForm.reclamationSubSubType && this.reclamationForm.reclamationSubSubType !== this.reclamation.reclamationSubSubType) form.append('reclamationSubSubType', this.reclamationForm.reclamationSubSubType.value)
      if (this.reclamationForm.userClient && this.reclamationForm.userClient !== this.reclamation.userClient) form.append('userClient', this.reclamationForm.userClient.id)
      if (this.reclamationForm.userTechnique && this.reclamationForm.userTechnique !== this.reclamation.userTechnique) form.append('userTechnique', this.reclamationForm.userTechnique.id)

      if (this.reclamationForm.contact1 && this.reclamationForm.contact1 !== this.reclamation.contact1) form.append('contact1', this.reclamationForm.contact1.id)
      if (this.reclamationForm.contact2 && this.reclamationForm.contact2 !== this.reclamation.contact2) form.append('contact2', this.reclamationForm.contact2.id)

      if (this.reclamationForm.notifiedUser1 && this.reclamationForm.notifiedUser1 !== this.reclamation.notifiedUser1) form.append('notifiedUser1', this.reclamationForm.notifiedUser1.id)
      if (this.reclamationForm.notifiedUser2 && this.reclamationForm.notifiedUser2 !== this.reclamation.notifiedUser2) form.append('notifiedUser2', this.reclamationForm.notifiedUser2.id)

      this.$store.dispatch('reclamations/patch', form)
        .then(() => {
          this.success()
          this.reclamationFiles = []
          this.files = []
          this.$refs.dropzone.removeAllFiles()
          this.$store.dispatch('reclamations/fetchOne', this.reclamation.id)
            .then(() => {
              this.loading = false
            })
        })
        .catch(() => {
          this.$swal(
            {
              title: `${this.$i18n.t('RECLAMATION_ERROR_POPUP')}`,
              html: `${this.$i18n.t('RECLAMATION_ERROR_POPUP_TEXT')}.`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            },
          )
          this.loading = false
        })
    },
    success() {
      this.$swal(
        {
          title: `${this.$i18n.t('RECLAMATION_UPDATED_POPUP_HEADER_1')}`,
          html: '',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        },
      )
    },
  },
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';

.reclamation-spacing {
    margin: 1.45rem 0;
}

.dz-progress { display: none; }

.dz-message:before {
    content: '';
    font-size: 80px;
    position: absolute;
    top:0;
    width: 80px;
    height: 80px;
    display: inline-block;
    line-height: 1;
    z-index: 2;
    color: #021738 ;
    text-indent: 0px;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
}
.dropzone-custom-title{
    font-size: 2rem;
    margin-top:5px;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 100%;
    color: #021738 ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.vue-dropzone .dz-preview .dz-details {
  background: none;
}

</style>
